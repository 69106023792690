.chartTitle:hover .maxIcon{
  opacity: 1;
}

.maxIcon {
opacity: 0;
}

.tooltip {
  background: #eee;
  box-shadow: 0 0 5px #999999;
  color: #333;
  display: none;
  font-size: 15px;
  left: 130px;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 95px;
  width: 120px;
  z-index: 10;
}

.tooltip1 {
  background: #eee;
  box-shadow: 0 0 5px #999999;
  color: #333;
  display: none;
  font-size: 15px;
  left: 130px;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 95px;
  width: 250px;
  z-index: 10;
}

rect.disabled {
  fill: transparent !important;
}