@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i,900");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://cdn.linearicons.com/free/1.0.0/svgembedder.min.js");
@import url("//fonts.googleapis.com/css?family=Open+Sans:400,600,700,800,300");

.toggleStyles {
  width: 100%;
  padding-bottom: 5px;
  cursor: pointer;
}

.topMenuIcons {
  width: 95%;
  padding-bottom: 14%;
  margin-left: 30%;
}

.topMenuSettingIcons {
  width: 45%;
  padding-bottom: 13%;
  margin-left: 40%;
}

.downArrow {
  color: white;
  position: absolute;
  top: 43%;
  right: 0;
  margin-top: -2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transition: all 0.2s ease-in-out;
}

.cardstyle {
  margin: -1% !important;
  border: none !important;
  box-shadow: 0px 0px 0px 0px #707070 !important;
  width: 13.5rem !important;
  padding: 0px !important;
}

.react-pdf__Page__canvas {
  width: 597px !important;
}

.ant-result {
  padding: 0px !important;
}

.popOver {
  padding: 0px 0px !important;
  width: 15rem !important;
}

.loginPageButton {
  width: 100% !important;
  font-size: 14px !important;
  background-color: #1648aa !important;
  border: 1px solid #e4e9f0 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.loginText {
  text-align: center;
}

.inner {
  min-width: 20rem;
  max-width: 27rem;
  margin: 0 auto;
  margin-left: 17%;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f2f3f6 !important;
  position: relative;
}

.textinner {
  min-width: 25rem !important;
  max-width: 27.66rem;
  margin-top: 14%;
  margin-left: 5%;
  padding-left: 1%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 5px !important;
}

.block {
  padding: 12% 3.33rem 6.66rem 1.8%;
  width: 100%;
  align-self: center;
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logoStyles {
  width: 50%;
  padding-top: 5px;
  margin-left: -4%;
}

.newButtonForlist {
  font-size: 14px !important;
  height: 2rem !important;
  width: 5rem !important;
  background: #089ea4 !important;
  border: 0.5px solid #07888d !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  opacity: 1;
  font-weight: 600 !important;
}

.listCustomButtons {
  height: 35px !important;
  width: 35px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  float: right !important;
  margin-right: 5px !important;
}

.listCustomButtonsLast {
  height: 35px !important;
  width: 35px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  float: right !important;
}

.listButtonsImage {
  margin-top: 6% !important;
  margin-bottom: 6px !important;
  padding-left: 2px !important;
  padding-top: 2px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.aboveHeaderButtons {
  height: 30px !important;
  width: auto !important;
  font-weight: 600 !important;
  color: #717172 !important;
  border-radius: 3px !important;
  border: 0.1px solid #dddbda !important;
  opacity: 1;
  cursor: pointer;
  margin-left: 4px;
}

.quickAddButtons {
  height: 33px !important;
  width: 33px !important;
  color: #706e6b !important;
  border: 0.5px solid #dddbda !important;
  cursor: pointer;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px !important;
  float: right !important;
}
.onHover:hover {
  max-width: "100%";
  overflow-x: scroll;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.dragHandler:hover {
  cursor: move;
  background-color: #ccc;
}
